@font-face {
	font-family: 'Gobold';
	src: url('../fonts/Gobold.eot');
	src: local('Gobold'),
		url('../fonts/Gobold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gobold.woff') format('woff'),
		url('../fonts/Gobold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('../fonts/Oswald-Medium.eot');
	src: local('Oswald Medium'), local('Oswald-Medium'),
		url('../fonts/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Oswald-Medium.woff') format('woff'),
		url('../fonts/Oswald-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('../fonts/Oswald-Regular.eot');
	src: local('Oswald Regular'), local('Oswald-Regular'),
		url('../fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Oswald-Regular.woff') format('woff'),
		url('../fonts/Oswald-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('../fonts/Oswald-Light.eot');
	src: local('Oswald Light'), local('Oswald-Light'),
		url('../fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Oswald-Light.woff') format('woff'),
		url('../fonts/Oswald-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans.eot');
	src: local('Open Sans'), local('OpenSans'),
	url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
	url('../fonts/OpenSans.woff') format('woff'),
	url('../fonts/OpenSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arquitecta';
	src: url('../fonts/ArquitectaMedium.eot');
	src: local('Arquitecta Medium'), local('ArquitectaMedium'),
		url('../fonts/ArquitectaMedium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ArquitectaMedium.woff') format('woff'),
		url('../fonts/ArquitectaMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Whitney';
	font-style: normal;
	font-weight: normal;
	src: url('Whitney Medium'), url('../fonts/whitneymedium.woff') format('woff');
}

//Default Font for Everything
// *{ font-family: 'Open Sans'; }

h1, h2, h3, h4, h5, h6{
  font-family: 'Gobold';
	font-weight: bold;
	letter-spacing: 0.05em;
  line-height: 1.3em;
	text-transform: capitalize;
	// text-align: center;
	color: white;
}

p{ color: white; }

a{
  &, &:link, &:visited, &:hover, &:active{
    color: currentColor;
    text-decoration: none;
  }
}

h1{font-size: 2em;}
h1.hero{ font-size: 2.6em; }
h2{font-size: 1.7em;}
h3{font-size: 1.5em;}
h4{font-size: 1.05em;}
h5{font-size: 0.8em;}
h6{font-size: 0.65em; letter-spacing: 0;}

h1, h2, h3, h4, h5, h6{ font-weight: normal; }

// li, span{ font-size: 1em; font-family: 'Oswald'; }
p{ font-size: 1em; font-family: 'Open Sans'; }
p.fine-text{ font-size: 0.8em; color: #8a8a8a; }

//Desktop Above Font Sizing
@media all and (min-width: 992px){
	body{ font-size: 14px; }
}

//Tablet and Mobile
@media all and (max-width: 992px){
	body{ font-size: 12px; }
	h1.hero{ font-size: 2.5em; }
}

.font-14px {
	font-size: 14px;
}

.font-10px {
	font-size: 10px;
}
