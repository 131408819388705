ul.scroller-list{
  height: 440px;
  // border-bottom: lightgrey 2px solid;

  padding: 10px;

  overflow-y: scroll;
  list-style: none;

  p{
    display: inline-flex;
    align-items: center;
    width: 85%;
    margin: 0;

    color: grey;

    span{ display: inline-block; padding-left: 10px; padding-right: 10px;}

    span:first-child{
      color: dimgrey;
      font-weight: bold;
      width: 20%;
    }
    span:nth-child(2){ width: 45%; }
    span:nth-child(3){ width: 35%; }
  }

  li{
    display: inline-flex;
    align-items: center;

    width: 100%;

    padding: 10px 0;
    border-bottom: 1px lightgrey solid;

    &.filtered{
      display: none;
    }
  }

  &.row2{
    span{ padding: 0; width: initial !important; }
    span:nth-child(2){ padding-left: 3px; }
  }

  &.bursa{
    li{ justify-content: space-around;}
    // p span:first-child{ width: 10%; }
  }
}

.sorter{
  text-align: right;
  margin-bottom: 15px;

  span{
    font-family: 'open sans';
    font-weight: bold;
  }

  p{
    float: left;
    color: grey;
    span{ font-weight: bold; }
  }
}

.buttons{
  float: right;

  span{
    font-family: 'open sans';
    font-weight: bold;
    color: $biscay;
    cursor: pointer;

    transition: all 400ms $easeInOutQuint;

    &:not(:nth-child(2)):hover{
      color: $allports;
    }
  }
}

// ============================
// Media Page Version
// =====================================

li.media-list{
  margin: 0 !important;
  p span{
    width: 100% !important;
    transition: all 400ms $easeInOutQuint;
    &:hover{
      color: $allports !important;
    }
  }
}

.article-date{
  @extend .buttons;

  span{
    font-weight: normal;
    color: grey;
    cursor: initial;

    &:hover{ color: grey !important; }
  }
}

// ================
// Table Version
// =========================
table{
  width: 100%;
  margin-bottom: 20px;
  vertical-align: middle;

  th{
    background: $biscay;

    font-weight: normal;
    text-align: center;
    color: white;
  }

  td, th{
    border: 1px solid black;
    padding: 5px 10px;

    p{
      color: grey;
      font-size: 0.9em;
      margin: 0;
    }
  }

  &.row2{
    td{
      &:first-child{ width: 85%; }
      &:nth-child(2){ width: 15%; text-align: center;}
    }
  }

  &.row3{
    td{
      width: 15%;
      text-align: center;
      &:first-child{ width: 70%; text-align: left; }
    }
  }
}

.img-container{
  height: 700px;
  overflow: auto;

  img{
    max-width: 100%;
  }
}

@media all and (max-width: 768px){

  table{
    margin: 20px auto;
  }

  ul.scroller-list{
    height: 380px;
    overflow: scroll;

    li{ min-width: 600px; }
  }

  .img-container{
    margin-top: 20px;
  }

}


// ================
// Color font
// =========================

.description {
  color: $biscay;
}
