/* Imports */
@font-face {
  font-family: "Gobold";
  src: url("../fonts/Gobold.eot");
  src: local("Gobold"), url("../fonts/Gobold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gobold.woff") format("woff"), url("../fonts/Gobold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald-Medium.eot");
  src: local("Oswald Medium"), local("Oswald-Medium"), url("../fonts/Oswald-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Oswald-Medium.woff") format("woff"), url("../fonts/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald-Regular.eot");
  src: local("Oswald Regular"), local("Oswald-Regular"), url("../fonts/Oswald-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Oswald-Regular.woff") format("woff"), url("../fonts/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald-Light.eot");
  src: local("Oswald Light"), local("Oswald-Light"), url("../fonts/Oswald-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Oswald-Light.woff") format("woff"), url("../fonts/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans.eot");
  src: local("Open Sans"), local("OpenSans"), url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans.woff") format("woff"), url("../fonts/OpenSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Arquitecta";
  src: url("../fonts/ArquitectaMedium.eot");
  src: local("Arquitecta Medium"), local("ArquitectaMedium"), url("../fonts/ArquitectaMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/ArquitectaMedium.woff") format("woff"), url("../fonts/ArquitectaMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Whitney";
  font-style: normal;
  font-weight: normal;
  src: url("Whitney Medium"), url("../fonts/whitneymedium.woff") format("woff");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Gobold";
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.3em;
  text-transform: capitalize;
  color: white;
}

p {
  color: white;
}

a, a:link, a:visited, a:hover, a:active {
  color: currentColor;
  text-decoration: none;
}

h1 {
  font-size: 2em;
}

h1.hero {
  font-size: 2.6em;
}

h2 {
  font-size: 1.7em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.05em;
}

h5 {
  font-size: 0.8em;
}

h6 {
  font-size: 0.65em;
  letter-spacing: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

p {
  font-size: 1em;
  font-family: "Open Sans";
}

p.fine-text {
  font-size: 0.8em;
  color: #8a8a8a;
}

@media all and (min-width: 992px) {
  body {
    font-size: 14px;
  }
}
@media all and (max-width: 992px) {
  body {
    font-size: 12px;
  }
  h1.hero {
    font-size: 2.5em;
  }
}
.font-14px {
  font-size: 14px;
}

.font-10px {
  font-size: 10px;
}

/* UI Element Stylings */
.rounded-button {
  position: relative;
  font-family: "Arquitecta";
  font-style: italic;
  min-width: 220px;
  height: auto;
  padding: 3px 0;
  border-radius: 20px;
  color: white;
  text-align: center;
  border: 2px solid white;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
}
.rounded-button, .rounded-button:before {
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.rounded-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 110%;
  max-width: 0;
  height: 100%;
  z-index: -1;
}
.rounded-button:hover {
  color: #1e3a6d;
}
.rounded-button:hover:before {
  max-width: 110%;
}
.rounded-button.__overlay {
  padding: 0;
  min-width: 150px;
  color: #0772A5;
  border: 2px solid #0772A5;
}
.rounded-button.__overlay a {
  justify-content: center !important;
}
.rounded-button.__overlay:before {
  background: #0772A5;
}
.rounded-button.__overlay:hover {
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .corporate-information span.rounded-button.__overlay {
    min-width: 150px !important;
  }
  .rounded-button.__overlay {
    min-width: 120px !important;
  }
  .rounded-button {
    margin-right: 0.5rem !important;
  }
  .agm-egm li.agm-link-file a {
    width: 58% !important;
  }
  .corporate-information li.link-file a {
    width: auto !important;
  }
  .corporate-information span.rounded-button a {
    width: 80% !important;
  }
}
.corporate-governance-report .list-styled,
.agm-egm .list-styled {
  border-bottom: 1px lightgrey solid;
  padding-left: 0px;
}
.corporate-governance-report .list-styled li,
.agm-egm .list-styled li {
  list-style: none;
}

.corporate-information .list-styled {
  padding-left: 0px;
}
.corporate-information .list-styled li {
  list-style: none;
  margin-bottom: 10px;
}

.corporate-governance-report li.agm-link-file,
.agm-egm li.agm-link-file,
.corporate-information li.link-file {
  border-bottom: 1px solid lightgrey !important;
  padding: 10px 0px 18px !important;
}
.corporate-governance-report li.agm-link-file a,
.agm-egm li.agm-link-file a,
.corporate-information li.link-file a {
  display: inline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
  text-decoration: none;
  cursor: pointer;
}

.corporate-governance-report li.agm-link-file a,
.agm-egm li.agm-link-file a {
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
}

.corporate-governance-report .tab__section .title,
.agm-egm .tab__section .title {
  margin-bottom: 40px !important;
}

.corporate-governance-report .tab__section h4,
.agm-egm .tab__section h4 {
  margin-top: 18px !important;
}

.corporate-governance-report .tab__section ul,
.agm-egm .tab__section ul {
  padding-left: 5px;
}

.corporate-governance-report .tab__section ul li:last-child,
.agm-egm .tab__section ul li:last-child {
  margin-bottom: 18px !important;
}

.arrow-button {
  position: relative;
  display: inline-block;
  width: 100px;
  margin: 5px 0;
  font-family: "Arquitecta";
  font-style: italic;
  color: white;
  cursor: pointer;
  overflow: hidden;
  transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
}
.arrow-button:before, .arrow-button:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 14px;
  background-image: url("../img/arrow.svg");
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.arrow-button:before {
  left: -40px;
}
.arrow-button:after {
  right: 0;
}
.arrow-button:hover {
  padding-left: 30px;
}
.arrow-button:hover:before, .arrow-button:hover:after {
  transform: translate(40px, -50%);
}

.scrolltoTop {
  position: fixed;
  bottom: 8%;
  right: 3%;
  width: 35px;
  height: 28px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  z-index: 99;
  background-image: url("../img/scroll-to-top.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.scrolltoTop, .scrolltoTop:after {
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.scrolltoTop:after {
  content: "Scroll to Top";
  position: absolute;
  top: 100%;
  left: 50%;
  font-family: "open sans";
  font-size: 0.95em;
  white-space: nowrap;
  opacity: 0;
  transform: translate(-50%, -20%);
}
.scrolltoTop:hover {
  transform: translateY(-5px);
}
.scrolltoTop:hover:after {
  transform: translate(-50%, 0%);
  opacity: 1;
}
.scrolltoTop.is-shown {
  opacity: 1;
  pointer-events: all;
}

@media all and (max-width: 768px) {
  .scrolltoTop {
    bottom: 5%;
    right: 7%;
  }
}
.submit-slider {
  position: relative;
  border: 2px #1e3a6d solid;
  height: 37px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 17px;
  float: left;
}
.submit-slider .QapTcha {
  margin-top: 0;
  width: 100%;
  height: 100%;
  background: none;
}
.submit-slider .QapTcha:before {
  content: "SLIDE TO SUBMIT";
  position: absolute;
  top: 25%;
  color: #1e3a6d;
  font-size: 14px;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}
.submit-slider .QapTcha .bgSlider {
  width: 100%;
  border: 0;
  background: none;
}
.submit-slider .QapTcha .Slider {
  background: #1e3a6d;
}
.submit-slider .QapTcha .Slider:after {
  content: ">";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 26px;
}

.slide-box-fade {
  display: block;
  position: absolute;
  height: 37px;
  width: 0;
  max-width: 100%;
  left: 0;
  top: 0;
  background: lightgrey;
  opacity: 0;
}
.slide-box-fade.slide-stop {
  width: 0 !important;
  opacity: 0 !important;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}

/* Template for Sections */
@media all and (min-width: 768px) {
  header li, header span, footer li, footer span {
    font-size: 1.05em;
    font-family: "Oswald";
    font-weight: 400;
  }
  header li li, footer li li {
    font-size: 1em;
  }
  .nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .nav-links > li {
    display: inline-block;
    margin: 0 10px;
    color: #0772A5;
    cursor: pointer;
    transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  }
  .nav-links > li:hover {
    color: black;
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    padding: 7px 30px;
    z-index: 999;
    background: white;
    transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .header__container:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    height: 220px;
    max-height: 0;
    background: rgba(30, 58, 109, 0.9);
  }
  .header__image {
    height: 100%;
  }
  .header__image img {
    height: 100%;
    max-width: 320px;
    transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .menu-button {
    display: none;
    opacity: 0;
    pointer-events: none;
  }
  .dropdown__container {
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 25px;
    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .dropdown__links {
    list-style: none;
    padding: 0;
    padding-top: 10px;
    margin: 0;
  }
  .dropdown__links:before {
    content: "";
    position: absolute;
    top: 18px;
    left: 5%;
    width: 12px;
    height: 12px;
    background: white;
    transform: rotate(45deg);
  }
  .dropdown__links > li {
    display: inline-block;
    position: relative;
    margin: 2px auto;
    font-weight: 300;
    width: 200px;
    color: white;
    transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .dropdown__links > li:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    max-width: 0;
    background: white;
    transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  }
  .dropdown__links > li:hover {
    opacity: 1;
  }
  .dropdown__links > li:hover:before {
    opacity: 1;
    max-width: 100%;
  }
  .header__container:before, .dropdown__container {
    transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
    z-index: 2;
  }
  .header__container.is-open.px190:before {
    max-height: 190px;
  }
  .header__container.is-open.px180:before {
    max-height: 210px;
  }
  .header__container.is-open.px170:before {
    max-height: 170px;
  }
  .header__container.is-open.px160:before {
    max-height: 160px;
  }
  .header__container.is-open.px150:before {
    max-height: 150px;
  }
  .header__container.is-open.px140:before {
    max-height: 140px;
  }
  .header__container.is-open.px130:before {
    max-height: 130px;
  }
  .header__container.is-open.px120:before {
    max-height: 120px;
  }
  .header__container.is-open.px70:before {
    max-height: 70px;
  }
  .header__container.is-open.px50:before {
    max-height: 50px;
  }
  .dropdown.is-open .dropdown__container {
    max-height: 240px;
    opacity: 1;
  }
  footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 40px;
    padding: 10px 30px;
    background: white;
  }
  footer .nav-links {
    font-size: 0.9em;
  }
  footer .nav-links li {
    color: grey;
  }
  .footer__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .fine-text {
    margin: 0;
    padding: 0;
  }
  header.shrink-header {
    height: 50px;
  }
  header.shrink-header img {
    max-width: 270px;
  }
  header.shrink-header .dropdown__container {
    padding-top: 16px;
  }
  header.shrink-header .dropdown__links:before {
    top: 10px;
  }
}
@media all and (max-width: 768px) {
  header li, header span, footer li, footer span {
    font-family: "Oswald";
  }
  header li {
    font-size: 2.2em;
    color: white;
  }
  header li li {
    font-size: 0.6em;
    color: white;
    opacity: 0.75;
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  header li li:hover {
    opacity: 1;
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: white;
    z-index: 999;
  }
  .header__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
  }
  .header__container ul {
    list-style: none;
  }
  .header__container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -1;
  }
  .header__container:after {
    content: "";
    position: absolute;
    top: 0;
    right: 90px;
    width: 1px;
    height: 100%;
    background: #1e3a6d;
    opacity: 0.4;
  }
  .header__image {
    width: 65%;
  }
  .header__image img {
    width: 100%;
    max-width: 237px;
    max-height: 57px;
  }
  .menu-button {
    position: relative;
    text-align: center;
    font-size: 1.4em;
    color: #1e3a6d;
    padding: 0 5px;
    cursor: pointer;
    overflow: hidden;
  }
  .menu-button span {
    display: block;
    transform: translateX(0);
    transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  }
  .menu-button span:before {
    content: "CLOSE";
    position: absolute;
    left: -130%;
    top: 0;
  }
  .menu-button.is-open span {
    transform: translateX(130%);
  }
  header .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 60px);
    padding: 40px 0;
    padding-bottom: 80px;
    margin: 0;
    background: rgba(30, 58, 109, 0.95);
    text-align: center;
    overflow: auto;
    z-index: -2;
    transform: translateY(-100%);
    transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
    opacity: 0;
  }
  header .nav-links.is-open {
    transform: translateY(0%);
    opacity: 1;
  }
  header .nav-links > li {
    width: 200px;
    margin: 8px auto;
  }
  .dropdown__links {
    padding-left: 0;
  }
  .dropdown__links li {
    margin: 3px 0;
  }
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  footer .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  footer .nav-links li {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
  }
  footer p {
    text-align: center;
  }
}
ul.scroller-list {
  height: 440px;
  padding: 10px;
  overflow-y: scroll;
  list-style: none;
}
ul.scroller-list p {
  display: inline-flex;
  align-items: center;
  width: 85%;
  margin: 0;
  color: grey;
}
ul.scroller-list p span {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
ul.scroller-list p span:first-child {
  color: dimgrey;
  font-weight: bold;
  width: 20%;
}
ul.scroller-list p span:nth-child(2) {
  width: 45%;
}
ul.scroller-list p span:nth-child(3) {
  width: 35%;
}
ul.scroller-list li {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px lightgrey solid;
}
ul.scroller-list li.filtered {
  display: none;
}
ul.scroller-list.row2 span {
  padding: 0;
  width: initial !important;
}
ul.scroller-list.row2 span:nth-child(2) {
  padding-left: 3px;
}
ul.scroller-list.bursa li {
  justify-content: space-around;
}

.sorter {
  text-align: right;
  margin-bottom: 15px;
}
.sorter span {
  font-family: "open sans";
  font-weight: bold;
}
.sorter p {
  float: left;
  color: grey;
}
.sorter p span {
  font-weight: bold;
}

.buttons, .article-date {
  float: right;
}
.buttons span, .article-date span {
  font-family: "open sans";
  font-weight: bold;
  color: #1e3a6d;
  cursor: pointer;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.buttons span:not(:nth-child(2)):hover, .article-date span:not(:nth-child(2)):hover {
  color: #0772A5;
}

li.media-list {
  margin: 0 !important;
}
li.media-list p span {
  width: 100% !important;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
li.media-list p span:hover {
  color: #0772A5 !important;
}

.article-date span {
  font-weight: normal;
  color: grey;
  cursor: initial;
}
.article-date span:hover {
  color: grey !important;
}

table {
  width: 100%;
  margin-bottom: 20px;
  vertical-align: middle;
}
table th {
  background: #1e3a6d;
  font-weight: normal;
  text-align: center;
  color: white;
}
table td, table th {
  border: 1px solid black;
  padding: 5px 10px;
}
table td p, table th p {
  color: grey;
  font-size: 0.9em;
  margin: 0;
}
table.row2 td:first-child {
  width: 85%;
}
table.row2 td:nth-child(2) {
  width: 15%;
  text-align: center;
}
table.row3 td {
  width: 15%;
  text-align: center;
}
table.row3 td:first-child {
  width: 70%;
  text-align: left;
}

.img-container {
  height: 700px;
  overflow: auto;
}
.img-container img {
  max-width: 100%;
}

@media all and (max-width: 768px) {
  table {
    margin: 20px auto;
  }
  ul.scroller-list {
    height: 380px;
    overflow: scroll;
  }
  ul.scroller-list li {
    min-width: 600px;
  }
  .img-container {
    margin-top: 20px;
  }
}
.description {
  color: #1e3a6d;
}

.tile__container {
  padding: 7px;
}

.tile:hover .tile__content img {
  transform: scale(1, 1);
}
.tile:hover .tile__overlay {
  transform: translateY(0);
}

.tile__content {
  margin: auto;
  width: 228px;
  position: relative;
  overflow: hidden;
  border: 2px solid grey;
}
.tile__content img {
  width: 100%;
  height: auto;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  transform: scale(1.05, 1.05);
}

.tile__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.85);
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  transform: translateY(100%);
}
.tile__overlay h3 {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  text-align: center;
  color: #1e3a6d;
  font-weight: bold;
}

h4.tile__title {
  font-family: "Gobold";
  color: #1e3a6d;
  min-height: 60px;
  margin: 0 !important;
  margin-top: 5px !important;
  padding: 0 25px;
  text-align: center;
}

.ksm {
  text-align: left;
  margin: 10px 0;
  cursor: pointer;
}

.tab__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tab__list li {
  display: inline-block;
  width: 100%;
  margin: 5px 0;
  padding: 5px 15px;
  background: grey;
  font-family: "oswald";
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.tab__list li:hover {
  background: #1e3a6d;
}
.tab__list li.is-open {
  background: #1e3a6d;
}

.tab__content__container {
  position: relative;
  height: auto;
  min-height: 450px;
  padding: 0;
}

.tab__content {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.tab__content * {
  font-family: "open sans";
}
.tab__content p.fine-text {
  margin-top: 40px;
}
.tab__content p.fine-text span {
  display: block;
  font-weight: bold;
}
.tab__content ol.fine-text {
  padding-left: 20px;
  font-size: 0.8em;
  color: #8a8a8a;
}
.tab__content.is-open {
  position: static;
  display: block;
  opacity: 1;
  pointer-events: all;
}
.tab__content.block {
  display: block;
}
.tab__content.fadeOutTab {
  opacity: 0;
}
.tab__content.fadeInTab {
  opacity: 1;
}

.tab__content:not(.scroller) h4, .tab__content:not(.scroller) span {
  color: #1e3a6d;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}
.tab__content:not(.scroller) > span {
  position: relative;
  display: block;
  margin: 0;
}
.tab__content:not(.scroller) > span:nth-of-type(2n+2) {
  margin-bottom: 20px;
}
.tab__content:not(.scroller) > span:not(.no-arrow):before, .tab__content:not(.scroller) > span:not(.no-arrow):after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  width: 8px;
  height: 8px;
  background: #0772A5;
}
.tab__content:not(.scroller) > span:not(.no-arrow):before {
  transform: translateY(-50%) rotate(45deg);
}
.tab__content:not(.scroller) > span:not(.no-arrow):after {
  left: -24px;
  height: 12px;
  background: white;
}
.tab__content:not(.scroller) span span {
  font-weight: normal;
  color: #0091C3;
}
.tab__content:not(.scroller) ul {
  margin-bottom: 40px;
}
.tab__content:not(.scroller) li {
  color: #0091C3;
}

.tab__section h1, .tab__section h2 {
  color: #1e3a6d;
  font-family: "Gobold";
  font-weight: normal;
  text-transform: uppercase;
}
.tab__section h1 {
  font-size: 1.7em;
  text-decoration: underline;
}
.tab__section h2 {
  font-size: 1.4em;
}
.tab__section h3 {
  padding: 0;
  font-family: "open sans";
  font-weight: bold;
  font-size: 1em;
  color: #1e3a6d;
  text-decoration: underline;
}
.tab__section p, .tab__section li {
  color: #005a9e !important;
}
.tab__section ul {
  padding-left: 50px;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 10px !important;
}
.tab__section ul li {
  position: relative;
  margin-bottom: 10px;
}
.tab__section ul li:before {
  content: attr(num);
  position: absolute;
  transform: translateX(-100%);
  width: 45px;
  font-weight: bold;
  color: #1e3a6d;
}
.tab__section ul li li {
  margin-bottom: 10px !important;
}
.tab__section ul li li:before {
  width: 30px;
}
.tab__section ul li:last-child {
  margin-bottom: 30px;
}
.tab__section ul li:before {
  width: 45px;
}
.tab__section ul li li:before {
  width: 30px;
}
.tab__section ul li li li:before {
  width: 25px;
}
.tab__section ul ul {
  padding-left: 30px;
}
.tab__section ul.list-styled {
  list-style: disc;
  text-align: justify;
}
.tab__section ul.fine-text li {
  font-style: italic;
  font-size: 0.9em;
  margin: 0 !important;
}
.tab__section table td, .tab__section table th {
  color: #005a9e;
}
.tab__section table tr.split3 td:not(:first-child) {
  width: 25%;
}
.tab__section table ul li:last-child {
  margin-bottom: 0;
}

@media all and (max-width: 992px) {
  .tab__list {
    display: none;
  }
  .mobile-select__container {
    margin: 5px 0;
  }
  #mobile-select {
    width: 100%;
    padding: 10px;
    background: #1e3a6d;
    font-family: "open sans";
    color: white;
  }
}
@media all and (max-width: 768px) {
  .tab__content__container {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .tab__section > .row h3 {
    padding-left: 15px;
  }
  .tab__content[data-content-index="4"] {
    padding-left: 2rem;
  }
}
/*-----------------------------------
    Sub tab corporate  governance
------------------------------------*/
ul.tab__list li .dropdown {
  width: 102%;
  float: right;
}

ul.tab__list li .dropdown .dropdown-toggle {
  width: 100%;
}

.dropdown.active,
.collapsed span {
  background: #1e3a6d !important;
}

ul.tab__list li .dropdown .dropdown-toggle span:first-child {
  float: left;
  text-align: left;
  display: inline-block;
  width: 90%;
  margin: 0px 0;
  font-family: "oswald";
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  -webkit-transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}

ul.tab__list li .dropdown .dropdown-toggle span:nth-child(2) {
  float: right;
  margin-top: 2px;
}

ul.tab__list li .dropdown ul.expand-details li {
  font-family: "oswald";
  text-transform: uppercase;
  color: white;
  background: #1e3a6d !important;
  -webkit-transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  width: 100%;
  float: left;
  font-size: 14px;
  border-bottom: none;
  margin-left: 0px;
  list-style-position: outside;
}
ul.tab__list li .dropdown ul.expand-details li a {
  cursor: pointer;
}

#corporateGovernance .tab__section {
  border-top: 2px solid #1e3a6d;
  border-bottom: 2px solid #1e3a6d;
}
#corporateGovernance h2.tab__header,
#corporateGovernance h1.title {
  color: #1e3a6d;
  font-weight: 700;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  text-decoration: none;
}
#corporateGovernance .card {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 0px;
}
#corporateGovernance .panel {
  margin-bottom: 0px;
}
#corporateGovernance .card-header {
  border: 0;
  background-color: transparent;
  padding: 0;
}
#corporateGovernance button[data-toggle=collapse] {
  position: relative;
  width: 100%;
  padding: 1rem 2rem;
  text-align: start;
  color: #1e3a6d;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  white-space: normal !important;
}
#corporateGovernance button[data-toggle=collapse]:focus {
  outline: 0;
  box-shadow: none;
}
#corporateGovernance button[data-toggle=collapse]::after {
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  right: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url("../img/down-arrow.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  transition: all 250ms linear;
}
#corporateGovernance button[data-toggle=collapse].collapsed::after {
  transform: rotate(0deg);
}
#corporateGovernance .collapse,
#corporateGovernance .collapsing {
  padding: 1rem 2rem;
  padding-bottom: 3rem;
}
#corporateGovernance .collapse.in {
  display: block !important;
}
#corporateGovernance .tab__section ul li:before {
  font-weight: 300;
  color: #005a9e;
}
#corporateGovernance .py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#corporateGovernance .accordion.plus h2.tab__header {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
#corporateGovernance .accordion.plus h2.tab__header button[data-toggle=collapse] {
  font-size: 2.2rem;
  font-weight: bold;
  max-width: 100%;
  font-family: "Gobold";
  padding-left: 0px;
}
#corporateGovernance .accordion.plus h2.tab__header > button[data-toggle=collapse]::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  right: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  background-image: url("../img/minus.png") !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 250ms linear;
}
#corporateGovernance .accordion.plus h2.tab__header > button[data-toggle=collapse].collapsed::after {
  background-image: url("../img/plus.png") !important;
  transform: rotate(360deg);
}
#corporateGovernance .accordion.plus > .card {
  border-bottom: 2px solid #1e3a6d;
}
#corporateGovernance .accordion.plus > .card:last-child {
  border-bottom: 0px;
}

body:not(.version-ie) {
  background: rgb(240, 240, 240);
  pointer-events: none;
  overflow-y: scroll;
}
body:not(.version-ie) header, body:not(.version-ie) footer, body:not(.version-ie) .main__content {
  opacity: 0 !important;
}
body:not(.version-ie).is-shown {
  background: white;
  pointer-events: initial;
}
body:not(.version-ie).is-shown header, body:not(.version-ie).is-shown footer, body:not(.version-ie).is-shown .main__content {
  opacity: 1 !important;
}

.loader__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  pointer-events: none;
}

.loader {
  width: 102%;
  height: 100%;
  background: rgb(240, 240, 240);
  transition: all 1200ms cubic-bezier(0.86, 0, 0.07, 1);
}
.loader.exit-loader {
  transform: translateX(-120%) skew(30deg);
}

.loader__container.is-entering .loader {
  transform: translateX(-120%) skew(30deg);
}

.loader__container.is-exiting .loader {
  transform: none;
}

@media all and (max-width: 768px) {
  .loader.exit-loader {
    transform: translateX(-155%) skew(30deg);
  }
  .loader__container.is-entering .loader {
    transform: translateX(-155%) skew(30deg);
  }
}
/* Base Styling */
body {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  padding-top: 50px;
  padding-bottom: 70px;
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-x: hidden;
}
body.landing {
  min-height: 810px;
  padding-bottom: 40px;
}

.w-100 {
  width: 100%;
}

.active-pages {
  border: 1px solid rgba(255, 0, 0, 0.5) !important;
}

.main__content {
  position: relative;
  padding-top: 50px;
  min-height: 400px;
}
.main__content.landing {
  padding: 0;
  margin-top: 20px;
}

.slider {
  position: relative;
  width: 100vw;
  padding: 0;
  overflow: hidden;
  z-index: 1;
}

.slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: calc(100vh - 70px - 40px);
  min-height: 700px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 250px;
  background-size: cover;
}
.slide[data-num="1"] {
  background-image: url("../img/slider-landing.jpg");
}
.slide[data-num="2"] {
  background-image: url("../img/slider-treatment.jpg");
}
.slide[data-num="3"] {
  background-image: url("../img/slider-highway.jpg");
}
.slide[data-num="4"] {
  background-image: url("../img/slider-construction.jpg");
}
.slide[data-num="5"] {
  background-image: url("../img/slider-waste.jpg");
}
.slide[data-num="6"] {
  background-image: url("../img/slider-solar-energy.jpg");
}
@media only screen and (max-width: 767px) {
  .slide[data-num="6"] {
    background-position: left 49% bottom 0%;
  }
}
.slide h1 {
  text-align: center;
}
.slide p {
  text-align: center;
  margin-bottom: 2%;
  max-width: 700px;
}
.slide:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
  opacity: 0.8;
  z-index: -1;
}

.bottom__container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
  z-index: 1;
}

.bottom__content {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}
.bottom__content h2,
.bottom__content p {
  margin: 0;
}
.bottom__content h2 {
  margin: 5px auto;
}
.bottom__content:nth-child(2) {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.hero__image {
  padding: 0;
  height: 350px;
}

.hero__image.waste-management {
  background-position: 21% center !important;
}

.section__content {
  padding-bottom: 20px;
}
.section__content h1,
.section__content h2,
.section__content h3 {
  color: #1e3a6d;
}
.section__content h4 {
  color: #005a9e;
}
.section__content address {
  color: #0772A5;
}
.section__content p {
  text-align: justify;
  margin-bottom: 15px;
  color: #0772A5;
}
.section__content ul,
.section__content ol {
  margin-bottom: 20px;
}
.section__content ul li,
.section__content ol li {
  color: #0772A5;
  font-family: "Open Sans";
  margin-bottom: 5px;
}
.section__content ul li p,
.section__content ol li p {
  margin: 0;
  padding-left: 10px;
}
.section__content ul.noindent {
  padding-left: 20px;
}
.section__content ol {
  list-style: lower-latin;
}
.section__content img {
  width: 100%;
  max-width: 1180px;
  height: auto;
  margin-bottom: 40px;
}
.section__content.sitemap ul {
  list-style: none;
  padding-left: 0;
}
.section__content.sitemap ul li {
  font-size: 1.1em;
  color: #005a9e;
}
.section__content.sitemap ul li:hover {
  color: #0091C3;
}

.section__icons {
  display: flex;
  text-align: center;
  margin: 20px 0;
}
.section__icons img {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 10px;
  height: auto;
}
.section__icons p {
  margin: 0;
  font-weight: 500;
  color: #0772A5;
}

address {
  color: #0091C3;
}

.timeline__content h1 {
  color: #0091C3;
}
.timeline__content ul {
  padding-left: 20px;
}
.timeline__content ul li {
  margin-bottom: 20px;
  font-family: "open sans";
  text-align: justify;
  color: grey;
}
.timeline__content ul li ul > li {
  padding-left: 16px;
}

.period {
  position: relative;
}
.period:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  height: 100%;
  width: 2px;
  background: grey;
}
.period:not(:first-child) {
  margin-top: -25px;
}
.period .col-md-6:not(.pull-right) {
  padding-right: 30px;
}
.period .col-md-6.pull-right {
  padding-left: 30px;
}
.period .col-md-6 {
  position: relative;
}
.period .col-md-6:before {
  content: "";
  position: absolute;
  top: 30px;
  left: -9px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f3f3f3;
  border: 2px solid grey;
}
.period:nth-of-type(2n+1) .col-md-6:before {
  left: initial;
  right: -11px;
}

.profile-sheet .profile__image {
  text-align: center;
  margin-bottom: 10px;
}
.profile-sheet .profile__image img {
  max-width: 350px;
  width: 100%;
  height: auto;
}
.profile-sheet table td:first-child {
  width: 15%;
}
.profile-sheet table td {
  padding: 10px;
}
.profile-sheet table li,
.profile-sheet table p,
.profile-sheet table td {
  color: #005a9e;
  font-size: 1em;
}
.profile-sheet table ul {
  margin: 0;
  padding-left: 15px;
}
.profile-sheet table p {
  margin-bottom: 10px;
  font-family: inherit;
}

form label {
  padding: 5px;
  width: 100%;
}
form label span {
  display: inline-block;
  padding: 5px 0;
  color: #1e3a6d;
  width: 200px;
}
form input[type=file] {
  display: inline-block;
  margin-left: 10px;
}
form input:not([type=file]),
form select {
  display: inline-block;
  position: relative;
  padding: 5px;
  margin-left: 10px;
  width: 65%;
  max-width: 600px;
  background: rgba(211, 211, 211, 0.5);
  color: #1e3a6d;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 2px solid rgba(30, 58, 109, 0);
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
form input:not([type=file]):focus,
form select:focus {
  outline: none;
  background: none;
  border-bottom: 2px solid #1e3a6d;
}
form input:not([type=file]):active,
form select:active {
  box-shadow: none;
  outline: none;
}
form select {
  color: grey;
  border: none !important;
  cursor: pointer;
}
form select:focus {
  background: rgba(211, 211, 211, 0.5);
}

@media all and (max-width: 768px) {
  body {
    padding-top: 60px;
  }
  body.landing {
    padding-bottom: 70px;
  }
  .hero__image.waste {
    background-position-x: 40%;
  }
  .main__content.landing {
    margin: 0;
    height: auto;
  }
  .main__content.scrolling-content {
    height: 900px;
  }
  .section__content {
    padding: 0;
  }
  .section__icons {
    display: block !important;
  }
  .section__icons img {
    margin-top: 0.7rem;
    width: 100%;
    max-width: 80px;
  }
  .section__icons > div {
    float: none !important;
    margin: auto !important;
    height: auto;
  }
  .section__icons .caption-icon {
    margin-bottom: 1.6rem;
  }
  .slider {
    width: 100%;
  }
  .slider[data-num="1"] {
    background-position: center;
  }
  .slider[data-num="2"] {
    background-position: center;
  }
  .slider[data-num="3"] {
    background-position: center;
  }
  .slider[data-num="4"] {
    background-position: 70%;
  }
  .slider[data-num="5"] {
    background-position: 70%;
  }
  .slide {
    min-height: 900px;
    padding-bottom: 400px;
  }
  .slide p {
    margin-bottom: 20px;
  }
  .bottom__container {
    bottom: 1%;
  }
  .bottom__content {
    margin: 5px 0;
    border: none !important;
  }
  .timeline__content h1 {
    color: #0091C3;
  }
  .timeline__content p {
    padding-right: 15px;
  }
  .period {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 3% !important;
  }
  .period:before {
    display: none;
  }
  .period .col-md-6 {
    padding-left: 26px !important;
    padding-right: 0 !important;
  }
  .period .col-md-6:before {
    width: 10px;
    height: 10px;
    left: 7px !important;
    right: initial !important;
  }
  form input:not([type=file]),
  form select {
    width: 100%;
    max-width: 400px;
    margin-left: 0;
  }
}
@media print {
  header {
    position: static;
  }
  .header__image {
    width: 237px;
    height: 57px;
  }
  .parallax-mirror,
  .hero__image,
  .tab__list {
    display: none;
  }
  .section__icons img {
    width: 120px;
  }
}
h1 > sup {
  text-transform: none;
}

b {
  font-weight: bolder;
}

.profile-sheet p {
  color: #005a9e;
  text-align: justify;
}

.faded {
  position: relative;
  display: inline-block;
  color: white;
}
.faded:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}
.faded.faded-left:after {
  box-shadow: inset 40px 0 40px -16px;
}
.faded.faded-right:after {
  box-shadow: inset -40px 0 40px -16px;
}
.faded.faded-top:after {
  box-shadow: inset 0 40px 40px -16px;
}
.faded.faded-bottom:after {
  box-shadow: inset 0 -40px 40px -16px;
}
.faded.faded-left.faded-right:after {
  box-shadow: inset 40px 0 40px -16px, inset -40px 0 40px -16px;
}
.faded.faded-left.faded-top:after {
  box-shadow: inset 40px 40px 40px -16px;
}
.faded.faded-right.faded-bottom:after {
  box-shadow: inset -40px -40px 40px -16px;
}
.faded.faded-top.faded-right:after {
  box-shadow: inset -40px 40px 40px -16px;
}
.faded.faded-top.faded-bottom:after {
  box-shadow: inset 0 40px 40px -16px, inset 0 -40px 40px -16px;
}
.faded.faded-left.faded-bottom:after {
  box-shadow: inset 40px -40px 40px -16px;
}
.faded.faded-top.faded-right.faded-bottom:after {
  box-shadow: inset -20px 0 40px 16px;
}
.faded.faded-left.faded-right.faded-bottom:after {
  box-shadow: inset 0 -20px 40px 16px;
}
.faded.faded-left.faded-top.faded-bottom:after {
  box-shadow: inset 20px 0 40px 16px;
}
.faded.faded-left.faded-top.faded-right:after {
  box-shadow: inset 0 20px 40px 16px;
}
.faded.faded-all:after, .faded.faded-left.faded-top.faded-right.faded-bottom:after {
  box-shadow: inset 0 0 40px 16px;
}
.faded.faded-narrow.faded-left:after {
  box-shadow: inset 20px 0 20px -10px;
}
.faded.faded-narrow.faded-right:after {
  box-shadow: inset -20px 0 20px -10px;
}
.faded.faded-narrow.faded-top:after {
  box-shadow: inset 0 20px 20px -10px;
}
.faded.faded-narrow.faded-bottom:after {
  box-shadow: inset 0 -20px 20px -10px;
}
.faded.faded-narrow.faded-left.faded-right:after {
  box-shadow: inset 20px 0 20px -10px, inset -20px 0 20px -10px;
}
.faded.faded-narrow.faded-left.faded-top:after {
  box-shadow: inset 20px 20px 20px -10px;
}
.faded.faded-narrow.faded-right.faded-bottom:after {
  box-shadow: inset -20px -20px 20px -10px;
}
.faded.faded-narrow.faded-top.faded-right:after {
  box-shadow: inset -20px 20px 20px -10px;
}
.faded.faded-narrow.faded-top.faded-bottom:after {
  box-shadow: inset 0 20px 20px -10px, inset 0 -20px 20px -10px;
}
.faded.faded-narrow.faded-left.faded-bottom:after {
  box-shadow: inset 20px -20px 20px -10px;
}
.faded.faded-narrow.faded-top.faded-right.faded-bottom:after {
  box-shadow: inset -10px 0 20px 10px;
}
.faded.faded-narrow.faded-left.faded-right.faded-bottom:after {
  box-shadow: inset 0 -10px 20px 10px;
}
.faded.faded-narrow.faded-left.faded-top.faded-bottom:after {
  box-shadow: inset 10px 0 20px 10px;
}
.faded.faded-narrow.faded-left.faded-top.faded-right:after {
  box-shadow: inset 0 10px 20px 10px;
}
.faded.faded-narrow.faded-all:after, .faded.faded-narrow.faded-left.faded-top.faded-right.faded-bottom:after {
  box-shadow: inset 0 0 20px 10px;
}
.faded.faded-wide.faded-left:after {
  box-shadow: inset 80px 0 80px -28px;
}
.faded.faded-wide.faded-right:after {
  box-shadow: inset -80px 0 80px -28px;
}
.faded.faded-wide.faded-top:after {
  box-shadow: inset 0 80px 80px -28px;
}
.faded.faded-wide.faded-bottom:after {
  box-shadow: inset 0 -80px 80px -28px;
}
.faded.faded-wide.faded-left.faded-right:after {
  box-shadow: inset 80px 0 80px -28px, inset -80px 0 80px -28px;
}
.faded.faded-wide.faded-left.faded-top:after {
  box-shadow: inset 80px 80px 80px -28px;
}
.faded.faded-wide.faded-right.faded-bottom:after {
  box-shadow: inset -80px -80px 80px -28px;
}
.faded.faded-wide.faded-top.faded-right:after {
  box-shadow: inset -80px 80px 80px -28px;
}
.faded.faded-wide.faded-top.faded-bottom:after {
  box-shadow: inset 0 80px 80px -28px, inset 0 -80px 80px -28px;
}
.faded.faded-wide.faded-left.faded-bottom:after {
  box-shadow: inset 80px -80px 80px -28px;
}
.faded.faded-wide.faded-top.faded-right.faded-bottom:after {
  box-shadow: inset -40px 0 80px 28px;
}
.faded.faded-wide.faded-left.faded-right.faded-bottom:after {
  box-shadow: inset 0 -40px 80px 28px;
}
.faded.faded-wide.faded-left.faded-top.faded-bottom:after {
  box-shadow: inset 40px 0 80px 28px;
}
.faded.faded-wide.faded-left.faded-top.faded-right:after {
  box-shadow: inset 0 40px 80px 28px;
}
.faded.faded-wide.faded-all:after, .faded.faded-wide.faded-left.faded-top.faded-right.faded-bottom:after {
  box-shadow: inset 0 0 80px 28px;
}

.popup-agm {
  background-image: url("../img/popup-2021-1.jpg");
}
@media only screen and (max-width: 600px) {
  .popup-agm {
    max-height: none;
    background-image: url("../img/popup-2021-1-mobile.jpg");
    background-position: top left;
  }
  .popup-agm .modal-main .title {
    margin-top: 33vw;
  }
}

.mobile-one-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px) {
  .mobile-one-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
  }
}
#accordionMilestone .btn.btn-link {
  width: 100%;
  text-align: left;
}
#accordionMilestone .btn.btn-link:hover,
#accordionMilestone .btn.btn-link:focus {
  text-decoration: none;
  border: none;
  outline: none;
}
#accordionMilestone .collapse.in li {
  background-color: transparent !important;
}

#accordionMilestone .fa {
  margin-right: 0.5rem;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

#accordionMilestone button, #accordionMilestone button:hover, #accordionMilestone button:focus {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .container-group-structure {
    padding-right: 1rem;
  }
}

/* CSS Animations */
.pause-anim {
  opacity: 0;
  transition: all 50ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.animated span {
  display: block;
}
.animated.slideUpMasked > span {
  overflow: hidden;
}
.animated.slideUpMasked > span span {
  opacity: 0;
  animation-name: slideUpMasked;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.6s;
}
.animated.fadeInTop span span {
  opacity: 0;
  animation-name: fadeInTop;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.6s;
}
.animated.fadeInTop span span {
  opacity: 0;
  animation-name: fadeInBottom;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.6s;
}
.animated.seq1 span:nth-child(2) span {
  animation-delay: 100ms;
}
.animated.seq1 span:nth-child(3) span {
  animation-delay: 140ms;
}
.animated.seq1 span:nth-child(4) span {
  animation-delay: 140ms;
}
.animated.seq2 span:nth-child(1) span {
  animation-delay: 140ms;
}
.animated.seq2 span:nth-child(2) span {
  animation-delay: 196ms;
}
.animated.seq2 span:nth-child(3) span {
  animation-delay: 392ms;
}
.animated.seq2 span:nth-child(4) span {
  animation-delay: 588ms;
}
.animated.seq3 span:nth-child(1) span {
  animation-delay: 196ms;
}
.animated.seq3 span:nth-child(2) span {
  animation-delay: 274.4ms;
}
.animated.seq3 span:nth-child(3) span {
  animation-delay: 548.8ms;
}
.animated.seq3 span:nth-child(4) span {
  animation-delay: 823.2ms;
}
.animated.seq4 span:nth-child(1) span {
  animation-delay: 274.4ms;
}
.animated.seq4 span:nth-child(2) span {
  animation-delay: 384.16ms;
}
.animated.seq4 span:nth-child(3) span {
  animation-delay: 768.32ms;
}
.animated.seq4 span:nth-child(4) span {
  animation-delay: 1152.48ms;
}
.animated.seq5 span:nth-child(1) span {
  animation-delay: 384.16ms;
}
.animated.seq5 span:nth-child(2) span {
  animation-delay: 537.824ms;
}
.animated.seq5 span:nth-child(3) span {
  animation-delay: 1075.648ms;
}
.animated.seq5 span:nth-child(4) span {
  animation-delay: 1613.472ms;
}
.animated.seq6 span:nth-child(1) span {
  animation-delay: 537.824ms;
}
.animated.seq6 span:nth-child(2) span {
  animation-delay: 752.9536ms;
}
.animated.seq6 span:nth-child(3) span {
  animation-delay: 1505.9072ms;
}
.animated.seq6 span:nth-child(4) span {
  animation-delay: 2258.8608ms;
}

@keyframes slideUpMasked {
  0% {
    transform: translateY(55%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInTop {
  0% {
    transform: translateY(-55%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInBottom {
  0% {
    transform: translateY(55%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}