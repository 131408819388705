.tile__container {
  padding: 7px;
}

.tile {
  &:hover {
    .tile__content img {
      transform: scale(1, 1);
    }

    .tile__overlay {
      transform: translateY(0);
    }
  }
}

.tile__content {
  margin: auto;
  width: 228px;
  position: relative;
  overflow: hidden;
  border: 2px solid grey;


  img {
    width: 100%;
    height: auto;

    transition: all 400ms $easeInOutQuint;
    transform: scale(1.05, 1.05);
  }
}

.tile__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 15px;

  background: rgba(white, 0.85);

  transition: all 400ms $easeInOutQuint;
  transform: translateY(100%);

  h3 {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;

    text-align: center;
    color: $biscay;
    font-weight: bold;
  }
}

h4.tile__title {
  font-family: 'Gobold';
  color: $biscay;

  min-height: 60px;

  margin: 0 !important;
  margin-top: 5px !important;

  padding: 0 25px;
  text-align: center;
}

.ksm {
  text-align: left;
  margin: 10px 0;
  cursor: pointer;
}

// @media all and(max-width: 768px) {
//   .tile__container {
//     &:nth-of-type(2n+1) {
//       padding-left: 15px;
//       padding-right: 5px;
//     }

//     &:nth-of-type(2n+2) {
//       padding-left: 5px;
//       padding-right: 15px;
//     }
//   }
// }