.tab__list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li{
    display: inline-block;

    width: 100%;
    margin: 5px 0;
    padding: 5px 15px;

    background: grey;

    font-family: 'oswald';
    text-transform: uppercase;
    // font-style: italic;
    color: white;

    cursor: pointer;

    transition: all 400ms $easeInOutQuint;

    &:hover{
      background: $biscay;
    }

    &.is-open{
      background: $biscay;
    }
  }
}

.tab__content__container{
  position: relative;
  height: auto;
  min-height: 450px;
  padding: 0;

  // &.taller_tab{ min-height: 750px; }
}

.tab__content{
  position: absolute;
  display: none;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  // padding: 10px 20px;

  opacity: 0;
  pointer-events: none;
  transition: all 400ms $easeInOutQuint;

  *{ font-family: 'open sans'; }

  p.fine-text{
    margin-top: 40px;
    span{
      display: block;
      font-weight: bold;
    }
  }

  ol.fine-text{
    padding-left: 20px;
    font-size: 0.8em;
    color: #8a8a8a;
  }

  &.is-open{
    position: static;
    display: block;

    opacity: 1;
    pointer-events: all;
  }

  &.block{ display: block; }
  &.fadeOutTab{ opacity: 0; }
  &.fadeInTab{ opacity: 1; }
}

.tab__content:not(.scroller){
  h4, span{
    color: $biscay;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
  }

  & > span{
    position: relative;
    display: block;
    margin: 0;

    &:nth-of-type(2n+2){
      margin-bottom: 20px;
    }

    &:not(.no-arrow){
      $size: 8px;
      &:before, &:after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        width: $size;
        height: $size;
        background: $allports;
      }
      &:before{
        transform: translateY(-50%) rotate(45deg);
      }

      &:after{
        left: -24px;
        height:$size*1.5;
        background: white;
      }
    }
  }

  span span{ font-weight: normal; color: $pacific-blue; }

  ul{ margin-bottom: 40px; }
  li{ color: $pacific-blue; }
}

.tab__section{
  h1, h2{
    color: $biscay;
    font-family: 'Gobold';
    font-weight: normal;
    text-transform: uppercase;
  }

  h1{ font-size: 1.7em; text-decoration: underline;}
  h2{ font-size: 1.4em; }

  h3{
    padding: 0;

    font-family: 'open sans';
    font-weight: bold;
    font-size: 1em;
    color: $biscay;
    text-decoration: underline;
  }

  p, li{
    color: $cornflower-blue !important;
  }

  ul{
    padding-left: 50px;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 10px !important;

    li{
      position: relative;
      margin-bottom: 10px;

      &:before{
        content: attr(num);
        position: absolute;
        // left: -10px;
        transform: translateX(-100%);

        width: 45px;

        font-weight: bold;
        color: $biscay;
      }

      li{
        margin-bottom: 10px !important;
        &:before{ width: 30px; }
       }

      &:last-child{ margin-bottom: 30px; }
    }

    // numbering styling
    li:before{ width: 45px; }
    li li:before{ width: 30px; }
    li li li:before{ width: 25px; }

    ul{ padding-left: 30px; }
    &.list-styled{
      list-style: disc;
      text-align: justify;
    }
    &.fine-text {
      li{
        font-style: italic;
        font-size: 0.9em;
        margin: 0 !important;
      }
    }
  }

  table{
    td,th{ color: $cornflower-blue; }

    tr.split3{
      td:not(:first-child){
        width: 25%;
      }
    }

    ul{
      li{
        &:last-child{ margin-bottom: 0; }
      }
    }
  }
}

@media all and (max-width: 992px){
  .tab__list{ display: none; }

  .mobile-select__container{
    margin: 5px 0;
  }

  #mobile-select{
    width: 100%;
    // height: 35px;

    padding: 10px;

    background: $biscay;

    font-family: 'open sans';
    color: white;
  }
}

@media all and (max-width:768px){
  .tab__content__container{
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;

    &.taller_tab{
      // min-height: 400px;
    }
  }

  .tab__section > .row{
    h3{ padding-left: 15px; }
  }

  .tab__content[data-content-index="4"] {
    padding-left: 2rem;
  }
}


/*-----------------------------------
    Sub tab corporate  governance
------------------------------------*/

ul.tab__list li .dropdown {
	width: 102%;
	float: right;
}
ul.tab__list li .dropdown .dropdown-toggle {
	width: 100%;
}

.dropdown.active,
.collapsed span {
  background: #1e3a6d !important;
}

ul.tab__list li .dropdown .dropdown-toggle span:first-child {
	float: left;
	text-align: left;
  // width: 94%;

  display: inline-block;
    width: 90%;
    margin: 0px 0;
    font-family: 'oswald';
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    -webkit-transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
ul.tab__list li .dropdown .dropdown-toggle span:nth-child(2) {
  float: right;
  margin-top: 2px;
}

ul.tab__list li .dropdown ul.expand-details li {
  font-family: 'oswald';
  text-transform: uppercase;
  color: white;
  background: #1e3a6d !important;
  -webkit-transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
	width: 100%;
	float:left;
	font-size: 14px;
	border-bottom: none;
  margin-left: 0px;
  list-style-position: outside;
  a {
    cursor: pointer;
  }
}

$dark-blue: #1e3a6d;
$blue: #005a9e;
#corporateGovernance {

	.tab__section {
		border-top: 2px solid $dark-blue;
		border-bottom: 2px solid $dark-blue;
	}

	h2.tab__header,
	h1.title {
		color: $dark-blue;
		font-weight: 700;
		// font-size: 1.7em;
		font-size: 2.2rem;
		margin-bottom: 2rem;
		text-decoration: none;
	}

	.card {
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.25);
		border-radius: 0px;
	}

	.panel {
		margin-bottom: 0px;
	}

	.card-header {
		border: 0;
		background-color: transparent;
		padding: 0;
	}

	button[data-toggle=collapse] {
		position: relative;
		width: 100%;
		padding: 1rem 2rem;
		text-align: start;
		color: $dark-blue;
		font-weight: 700;
		text-transform: uppercase;
		background-color: transparent;
		white-space: normal !important;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 16px;
			height: 16px;
			right: 1rem;
			top: 0;
			bottom: 0;
			margin: auto;
			background-image: url('../img/down-arrow.png');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			transform: rotate(180deg);
			transition: all 250ms linear;
		}
	}

	button[data-toggle=collapse].collapsed {
		&::after {
			transform: rotate(0deg);
		}
	}

	.collapse,
	.collapsing {
		padding: 1rem 2rem;
		padding-bottom: 3rem;
	}

	.collapse.in {
		display: block !important;
	}

	.tab__section ul li:before {
		font-weight: 300;
		color: $blue;
	}

	.py-3 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

#corporateGovernance {
	.accordion.plus {

		h2.tab__header {
			margin-bottom: 0.5rem;
			margin-top: 0.5rem;
		}

		h2.tab__header button[data-toggle=collapse] {
			font-size: 2.2rem;
			font-weight: bold;
			max-width: 100%;
			font-family: 'Gobold';
			padding-left: 0px;
		}

		h2.tab__header > button[data-toggle=collapse]::after {
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			right: 1rem;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
			background-image: url('../img/minus.png') !important;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			transition: all 250ms linear;
		}

		h2.tab__header > button[data-toggle=collapse].collapsed::after {
			background-image: url('../img/plus.png') !important;
			transform: rotate(360deg);
		}


		> .card {
			border-bottom: 2px solid $dark-blue;
		}

		> .card:last-child {
			border-bottom: 0px;
		}
	}
}
