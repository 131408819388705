.scrolltoTop{
  position: fixed;
  bottom: 8%;
  right: 3%;

  width: 35px;
  height: 28px;

  opacity: 0;
  pointer-events: none;

  cursor: pointer;
  z-index: 99;

  background: {
    image: url('../img/scroll-to-top.svg');
    size: contain;
    repeat: no-repeat;
  }

  &, &:after{ transition: all 300ms $easeInOutCubic; }

  &:after{
    content: 'Scroll to Top';
    position: absolute;
    top: 100%;
    left: 50%;

    font: {
      family: 'open sans';
      size: 0.95em;
    }
    white-space: nowrap;

    opacity: 0;
    transform: translate(-50%, -20%);
  }

  &:hover{
    transform: translateY(-5px);
    &:after{
      transform: translate(-50%, 0%);
      opacity: 1;
    }
  }

  &.is-shown{
    opacity: 1;
    pointer-events: all;
  }
}

@media all and (max-width: 768px){
  .scrolltoTop{
    bottom: 5%;
    right: 7%;
  }
}
