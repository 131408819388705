@media all and (min-width: 768px){
  header, footer{
    li, span{ font-size: 1.05em; font-family: 'Oswald'; font-weight: 400; }
    li li{ font-size: 1em; }
  }

  .nav-links{
    list-style: none;

    padding: 0;
    margin: 0;

    & > li{
      display: inline-block;

      margin: 0 10px;

      color: $allports;
      cursor: pointer;

      transition: all 400ms $easeInOutQuint;

      &:hover{
        color: black;
      }
    }
  }

  header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;

    padding: 7px 30px;
    z-index: 999;

    background: white;
    transition: all 400ms $easeInOutCubic;
  }

  .header__container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;

    &:before{
      content: '';
      position: absolute;
      top: 100%;
      left: 0;

      width: 100vw;
      height: 220px;
      max-height: 0;
      background: rgba($biscay, 0.9);
    }
  }

  .header__image{
    height: 100%;

    img{
      height: 100%;
      max-width: 320px;
      transition: all 400ms $easeInOutCubic;
    }
  }

  .menu-button{
    display: none;
    opacity: 0;
    pointer-events: none;
  }

  .dropdown__container{
    position: absolute;
    // top: calc(100% + 25px);
    top: 100%;
    left: 0;

    padding-top: 25px;

    // close styles
    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    // close styles
  }

  .dropdown__links{
    list-style: none;
    padding: 0;
    padding-top: 10px;
    margin: 0;

    &:before{
      $size: 12px;
      content: '';
      position: absolute;
      top: -$size/2 +24px;
      left: 5%;

      width: $size;
      height: $size;

      background: white;

      transform: rotate(45deg);
    }

    & > li{
      display: inline-block;
      position: relative;
      margin: 2px auto;
      font-weight: 300;

      width: 200px;
      // white-space: nowrap;
      color: white;
      // opacity: 0.8;

      transition: all 400ms $easeInOutCirc;

      &:before{
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;

        width: 100%;
        height: 2px;
        max-width: 0;

        background: white;
        transition: all 400ms $easeInOutQuint;
      }

      &:hover{
        opacity: 1;
        &:before{
          opacity: 1;
          max-width: 100%;
        }
      }
    }
  }

  // ==========================
  // dropdown open animation & classes
  // ==================================
  //same transition timing to keep things consistent
  .header__container:before, .dropdown__container{
    transition: all 500ms $easeInOutQuint;
    z-index: 2;
  }

  .header__container.is-open{
    //different nav-sizing
    &.px190:before{ max-height: 190px; }
    &.px180:before{ max-height: 210px; }
    &.px170:before{ max-height: 170px; }
    &.px160:before{ max-height: 160px; }
    &.px150:before{ max-height: 150px; }
    &.px140:before{ max-height: 140px; }
    &.px130:before{ max-height: 130px; }
    &.px120:before{ max-height: 120px; }
    &.px70:before{ max-height: 70px; }
    &.px50:before{ max-height: 50px; }
  }

  .dropdown.is-open{
    .dropdown__container{
      max-height: 240px;
      opacity: 1;
    }
  }


  // ==================================
  // END dropdown open animation & classes
  // ==========================

  footer{
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100vw;
    height: 40px;

    padding: 10px 30px;

    background: white;

    .nav-links{
      font-size: 0.9em;
      li{ color: grey; }
    }
  }

  .footer__container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
  }

  .fine-text{
    margin: 0;
    padding: 0;
  }

  // =====================
  // Scroll Shrink Header Class
  // =============================

  header.shrink-header{
    $headersize: 50px;
    height: $headersize;

    img{
      max-width: 320px - $headersize;
    }

    .dropdown__container{
      padding-top: 16px;
    }

    .dropdown__links:before{
      top: 10px;
    }
  }

  // =============================
  // END Scroll Shrink Header Class
  // =====================
}

@media all and (max-width: 992px){

}

@media all and (max-width: 768px){
  header, footer{
    li, span{ font-family: 'Oswald'; }
  }

  // font sizing
  header{
    li{
      font-size: 2.2em;
      color: white;

      li{
        font-size: 0.6em;
        color: white;
        opacity: 0.75;

        transition: all 600ms $easeInOutCubic;

        &:hover{
          opacity: 1;
        }
      }
    }

  }

  header{
    position: fixed;
    top: 0;
    left: 0;

    height: 60px;
    width: 100%;
    background: white;

    z-index: 999;
  }

  .header__container{
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;

    padding: 10px 20px;

    ul{ list-style: none; }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: white;
      z-index: -1;
    }

    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 90px;
      width: 1px;
      height: 100%;
      background: $biscay;
      opacity: 0.4;
    }
  }

  .header__image{
    width: 65%;

    img{
      width: 100%;
      max-width: 237px;
      max-height: 57px;
    }
  }

  .menu-button{
    position: relative;
    text-align: center;
    font-size: 1.4em;

    color: $biscay;
    padding: 0 5px;

    cursor: pointer;
    overflow: hidden;

    span{
      display: block;
      transform: translateX(0);
      transition: all 600ms $easeInOutQuint;
      &:before{
        content: 'CLOSE';
        position: absolute;
        left: -130%;
        top: 0;
      }
    }

    &.is-open{
      span{
        transform: translateX(130%);
      }
    }
  }

  header .nav-links{
    position: absolute;
    top: 100%;
    left: 0;

    width: 100%;
    height: 100%;
    min-height: calc( 100vh - 60px);

    padding: 40px 0;
    padding-bottom: 80px;
    margin: 0;

    background: rgba($biscay, 0.95);
    text-align: center;
    overflow: auto;
    z-index: -2;

    transform: translateY(-100%);
    transition: all 600ms $easeInOutQuint;
    opacity: 0;

    &.is-open{
      transform: translateY(0%);
      opacity: 1;
    }

    & > li{
      width: 200px;
      margin: 8px auto;
    }
  }

  .dropdown__links{
    padding-left: 0;

    li{
      margin: 3px 0;
    }
  }


  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .nav-links{
      display: flex;
      justify-content: center;
      align-items: center;

      flex-wrap: wrap;

      padding: 0;
      list-style: none;

      li{
        display: inline-block;
        margin: 0 10px;

        cursor: pointer;
      }
    }

    p{ text-align: center; }
  }
}
