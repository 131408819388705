.submit-slider{
  position: relative;
  border: 2px $biscay solid;
  height: 37px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 17px;
  float: left;

  .QapTcha{
    margin-top: 0;
    width: 100%;
    height: 100%;
    background: none;

    &:before{
      content: "SLIDE TO SUBMIT";
      position: absolute;
      top: 25%;
      color: $biscay;
      font-size: 14px;
      left: 0;
      text-align: center;
      width: 100%;
      height: 100%;
    }

    .bgSlider{
      width: 100%;
      border: 0;
      background: none;
    }

    .Slider{
      background: $biscay;
      &:after{
        content: ">";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        text-align: center;
        color: white;
        font-size: 26px;
      }
    }
  }
}

.slide-box-fade{
  display: block;
  position: absolute;
  height: 37px;
  width: 0;
  max-width: 100%;
  left: 0;
  top: 0;
  background: lightgrey;
  opacity: 0;

  &.slide-stop{
    width: 0 !important;
    opacity: 0 !important;

    transition: all 400ms $easeInOutQuint;
  }
}
