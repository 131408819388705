.arrow-button{
  position: relative;
  display: inline-block;

  width: 100px;
  margin: 5px 0;

  font-family: 'Arquitecta';
  font-style: italic;
  color: white;

  cursor: pointer;
  overflow: hidden;

  transition:all 500ms $easeInOutQuint;

  &:before, &:after{
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    width: 25px;
    height: 14px;

    background: {
      image: url('../img/arrow.svg');
      size: cover;
      repeat: no-repeat;
    }

    transition:all 400ms $easeInOutQuint;

  }

  &:before{ left: -40px; }
  &:after{ right: 0; }

  &:hover{
    padding-left: 30px;

    &:before, &:after{ transform: translate(40px, -50%)}
  }
}
