
// Colors
$biscay          : #1e3a6d; // use with 70% opacity
$cornflower-blue : #005a9e;
$allports        : #0772A5;
$pacific-blue    : #0091C3;
$grey            : #808080;

// transition values
// ordered by intensity
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1); //default ease;
$easeInOutQuint : cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutCirc  : cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutExpo  : cubic-bezier(1, 0, 0, 1);
