.rounded-button{
  position: relative;
  font-family: 'Arquitecta';
  font-style: italic;

  min-width: 220px;
  height: auto;

  padding: 3px 0;
  border-radius: 20px;

  color: white;
  text-align: center;
  border: 2px solid white;

  cursor: pointer;
  overflow: hidden;

  z-index: 2;

  &, &:before{
    transition: all 400ms $easeInOutQuint;
  }

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    // transform: translateX(-50%) skewX(0deg);
    background: white;
    width: 110%;
    max-width: 0;
    height: 100%;
    z-index: -1;
  }

  &:hover{
    color: $biscay;
    &:before{
      // transform: translateX(-50%);
      max-width: 110%;
    }
  }

  // Smaller Blue overlay variant
  &.__overlay{
    padding: 0;
    min-width: 150px;
    color: $allports;
    border: 2px solid $allports;
    a {
      justify-content: center !important;
    }

    &:before{
      background: $allports;
    }

    &:hover{
      color: white !important;
    }
  }
}


@media only screen and (max-width: 600px) {
  .corporate-information span.rounded-button.__overlay {
    min-width: 150px !important;
  }
  .rounded-button.__overlay {
    min-width: 120px !important;
  }
  .rounded-button {
    margin-right: 0.5rem !important;
  }
  .agm-egm li.agm-link-file a {
    width: 58% !important;
  }
  .corporate-information li.link-file a {
    width: auto !important;
  }
  .corporate-information span.rounded-button a {
    width: 80% !important;
  }
}

.corporate-governance-report .list-styled,
.agm-egm .list-styled {
  border-bottom: 1px lightgrey solid;
  padding-left: 0px;
  li {
    list-style: none;
  }
}

.corporate-information .list-styled {
  padding-left: 0px;
  li {
    list-style: none;
    margin-bottom: 10px;
  }
}

.corporate-governance-report li.agm-link-file,
.agm-egm li.agm-link-file,
.corporate-information li.link-file {
  border-bottom: 1px solid lightgrey !important;
  padding: 10px 0px  18px !important;
  a {
    display: inline;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70%;
    text-decoration: none;
    cursor: pointer;
  }
}

.corporate-governance-report li.agm-link-file a,
.agm-egm li.agm-link-file a {
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
}

.corporate-governance-report .tab__section .title,
.agm-egm .tab__section .title {
  margin-bottom: 40px !important;
} 

.corporate-governance-report .tab__section h4,
.agm-egm .tab__section h4 {
  margin-top: 18px !important;
}    

.corporate-governance-report .tab__section ul,
.agm-egm .tab__section ul {
  padding-left: 5px;
}

.corporate-governance-report .tab__section ul li:last-child,
.agm-egm .tab__section ul li:last-child {
  margin-bottom: 18px !important;
}