body {
  position: relative;
  // background: grey;
  min-height: 100vh;
  width: 100vw;

  padding-top: 50px;
  padding-bottom: 70px;
  transition: all 400ms $easeInOutCubic;
  overflow-x: hidden;

  &.landing {
    min-height: calc(700px + 70px + 40px);
    padding-bottom: 40px;
  }
}

.w-100 {
  width: 100%;
}

.active-pages {
  border: 1px solid rgba(red, 0.5) !important;
}

// =======================
// Landing Page
// =============================

.main__content {
  position: relative;

  padding-top: 50px;
  // $footerh: 40px;
  // padding-bottom: $footerh + 70px;

  min-height: 400px;

  &.landing {
    padding: 0;
    margin-top: 20px;
  }
}

.slider {
  position: relative;
  width: 100vw;
  padding: 0;
  overflow: hidden;
  z-index: 1;
}

.slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  height: calc(100vh - 70px - 40px);
  min-height: 700px;

  padding: {
    left: 15px;
    right: 15px;
    bottom: 250px;
  }

  background: {
    size: cover;
  }

  &[data-num='1'] {
    background-image: url('../img/slider-landing.jpg');
  }

  &[data-num='2'] {
    background-image: url('../img/slider-treatment.jpg');
  }

  &[data-num='3'] {
    background-image: url('../img/slider-highway.jpg');
  }

  &[data-num='4'] {
    background-image: url('../img/slider-construction.jpg');
  }  

  &[data-num='5'] {
    background-image: url('../img/slider-waste.jpg');
  }

  &[data-num='6'] {
    background-image: url('../img/slider-solar-energy.jpg');
    @media only screen and (max-width: 767px) {
      background-position: left 49% bottom 0%;
    }
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 2%;

    max-width: 700px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0.8;
    z-index: -1;
  }
}

.bottom__container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;

  z-index: 1;
}

.bottom__content {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;

  h2,
  p {
    margin: 0;
  }

  h2 {
    margin: 5px auto;
  }

  &:nth-child(2) {
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
}

// ==================
// Content Page Template
// =========================
.hero__image {
  padding: 0;
  height: 350px;

}

.hero__image.waste-management {
  background-position: 21% center !important;
}

.section__content {
  padding-bottom: 20px;

  h1,
  h2,
  h3 {
    color: $biscay;
  }

  h4 {
    color: $cornflower-blue;
  }

  address {
    color: $allports;
  }

  p {
    text-align: justify;
    margin-bottom: 15px;
    color: $allports;
  }

  ul,
  ol {
    margin-bottom: 20px;

    li {
      color: $allports;
      font-family: 'Open Sans';
      margin-bottom: 5px;

      p {
        margin: 0;
        padding-left: 10px;
      }
    }
  }

  ul.noindent {
    padding-left: 20px
  }

  ol {
    list-style: lower-latin;
  }

  img {
    width: 100%;
    max-width: 1180px;
    height: auto;
    margin-bottom: 40px;
  }

  &.sitemap {
    ul {
      list-style: none;
      padding-left: 0;

      li {
        font-size: 1.1em;
        color: $cornflower-blue;

        &:hover {
          color: $pacific-blue;
        }
      }
    }
  }
}

.section__icons {
  display: flex;
  text-align: center;
  margin: 20px 0;

  img {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
    height: auto;
  }

  p {
    margin: 0;
    font-weight: 500;
    color: $allports;
  }
}

address {
  color: $pacific-blue;
}

// =========================
// END Content Page Template
// ==================

// =======================
// Corporate Milestones Page
// ==============================

.timeline__content {
  h1 {
    color: $pacific-blue;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 20px;
      font-family: 'open sans';
      text-align: justify;
      color: grey;
    }
  }

  ul li ul > li {
    padding-left: 16px;
  }
}

.period {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;

    display: block;
    height: 100%;
    width: 2px;

    background: grey;
  }

  &:not(:first-child) {
    margin-top: -25px;
  }

  // default margin
  // &:nth-child(2){ margin-top: -25px;}
  // &:nth-child(3){ margin-top: -25px;}
  // &:nth-child(4){ margin-top: -25px;}
  // &:nth-child(5){ margin-top: -25px;}
  // &:nth-child(6){ margin-top: -25px;}
  // &:nth-child(7){ margin-top: -155px;}
  // &:nth-child(8){ margin-top: -105px;}
  // &:nth-child(9){ margin-top: -25px;}
  // &:nth-child(10){ margin-top: -25px;}
  // &:nth-child(11){ margin-top: -305px;}
  // &:nth-child(12){ margin-top: -25px;}
  // &:nth-child(13){ margin-top: -105px;}
  // &:nth-child(14){ margin-top: -25px;}
  // &:nth-child(15){ margin-top: -25px;}
  // &:nth-child(16){ margin-top: -95px;}
  // &:nth-child(17){ margin-top: -25px;}
  // &:nth-child(18){ margin-top: -25px;}
  // &:nth-child(19){ margin-top: -305px;}
  // &:nth-child(20){ margin-top: -305px;}
  // &:nth-child(21){ margin-top: -200px;}

  .col-md-6:not(.pull-right) {
    padding-right: 30px;
  }

  .col-md-6.pull-right {
    padding-left: 30px;
  }

  .col-md-6 {
    position: relative;

    &:before {
      $size: 20px;
      content: '';
      position: absolute;
      top: 30px;
      left: -9px;
      width: $size;
      height: $size;
      border-radius: 50%;
      background: #f3f3f3;
      border: 2px solid grey;
    }
  }

  &:nth-of-type(2n+1) {
    .col-md-6:before {
      left: initial;
      right: -11px;
    }
  }
}

// ==============================
// END Corporate Milestones Page
// =======================

// ====================================
// Profile Sheet styling
// =========================================

.profile-sheet {
  .profile__image {
    text-align: center;
    margin-bottom: 10px;

    img {
      max-width: 350px;
      width: 100%;
      height: auto;
    }
  }

  table {
    td:first-child {
      width: 15%;
    }

    td {
      padding: 10px;
    }

    li,
    p,
    td {
      color: $cornflower-blue;
      font-size: 1em;
    }

    ul {
      margin: 0;
      padding-left: 15px;
    }

    p {
      margin-bottom: 10px;
      font-family: inherit;
    }
  }
}

// =========================================
// END Profile Sheet styling
// ====================================


form {
  label {
    padding: 5px;
    width: 100%;

    span {
      display: inline-block;
      padding: 5px 0;
      color: $biscay;
      width: 200px;
    }
  }

  input[type='file'] {
    display: inline-block;
    margin-left: 10px;
  }

  input:not([type='file']),
  select {
    display: inline-block;
    position: relative;

    padding: 5px;
    margin-left: 10px;
    width: 65%;
    max-width: 600px;

    background: rgba(lightgrey, 0.5);
    color: $biscay;

    box-shadow: none;
    outline: none;
    border: none;
    border-bottom: 2px solid rgba($biscay, 0);

    transition: all 200ms $easeInOutCubic;

    &:focus {
      outline: none;
      background: none;
      border-bottom: 2px solid rgba($biscay, 1);
    }

    &:active {
      box-shadow: none;
      outline: none;
    }
  }

  select {
    color: grey;
    border: none !important;
    cursor: pointer;

    &:focus {
      background: rgba(lightgrey, 0.5);
    }
  }
}

@media all and (max-width: 768px) {
  body {
    padding-top: 60px;

    &.landing {
      padding-bottom: 70px;
    }
  }

  .hero__image {
    &.waste {
      background-position-x: 40%;
    }
  }

  .main__content {
    &.landing {
      margin: 0;
      height: auto;
    }

    &.scrolling-content {
      height: 900px;
    }
  }

  .section__content {
    padding: 0;
  }

  .section__icons {
    display: block !important;
    img {
      margin-top: 0.7rem;
      width: 100%;
      max-width: 80px;
    }

    &>div {
    float: none !important;
    margin: auto !important;
    height: auto;
  }

  .caption-icon {
    margin-bottom: 1.6rem;
  }
}

  // =======================
  // Landing Page
  // =============================

  .slider {
    width: 100%;

    &[data-num='1'] {
      background-position: center;
    }

    &[data-num='2'] {
      background-position: center;
    }

    &[data-num='3'] {
      background-position: center;
    }

    &[data-num='4'] {
      background-position: 70%;
    }

    &[data-num='5'] {
      background-position: 70%;
    }
  }

  .slide {
    min-height: 900px;
    padding-bottom: 400px;

    p {
      margin-bottom: 20px;
    }
  }

  .bottom__container {
    bottom: 1%;
  }

  .bottom__content {
    margin: 5px 0;
    border: none !important;
  }

  // =======================
  // Corporate Milestones Page
  // =============================
  .timeline__content {
    h1 {
      color: $pacific-blue;
    }

    p {
      padding-right: 15px;
    }
  }

  .period {
    padding-left: 30px;
    padding-right: 30px;

    margin-top: 3% !important;

    &:before {
      display: none;
    }

    .col-md-6 {
      padding-left: 26px !important;
      padding-right: 0 !important;

      &:before {
        $size: 10px;
        width: $size;
        height: $size;
        left: 7px !important;
        right: initial !important;
      }
    }
  }

  form {

    input:not([type='file']),
    select {
      width: 100%;
      max-width: 400px;
      margin-left: 0;
    }
  }
}

@media print {
  header {
    position: static;
  }

  .header__image {
    width: 237px;
    height: 57px;
  }

  .parallax-mirror,
  .hero__image,
  .tab__list {
    display: none;
  }

  .section__icons img {
    width: 120px;
  }
}

h1>sup {
  text-transform: none;
}

b {
  font-weight: bolder;
}

.profile-sheet {
  p {
    color: #005a9e;
    text-align: justify;
  }
}

@mixin faded($offset, $blur-radius, $spread-radius) {
  &.faded-left:after {
    box-shadow: inset $offset 0 $blur-radius (-$spread-radius);
  }

  &.faded-right:after {
    box-shadow: inset (-$offset) 0 $blur-radius (-$spread-radius);
  }

  &.faded-top:after {
    box-shadow: inset 0 $offset $blur-radius (-$spread-radius);
  }

  &.faded-bottom:after {
    box-shadow: inset 0 (-$offset) $blur-radius (-$spread-radius);
  }

  &.faded-left.faded-right:after {
    box-shadow: inset $offset 0 $blur-radius (-$spread-radius), inset (-$offset) 0 $blur-radius (-$spread-radius);
  }

  &.faded-left.faded-top:after {
    box-shadow: inset $offset ($offset) $blur-radius (-$spread-radius);
  }

  &.faded-right.faded-bottom:after {
    box-shadow: inset (-$offset) (-$offset) $blur-radius (-$spread-radius);
  }

  &.faded-top.faded-right:after {
    box-shadow: inset (-$offset) $offset $blur-radius (-$spread-radius);
  }

  &.faded-top.faded-bottom:after {
    box-shadow: inset 0 $offset $blur-radius (-$spread-radius), inset 0 (-$offset) $blur-radius (-$spread-radius);
  }

  &.faded-left.faded-bottom:after {
    box-shadow: inset $offset (-$offset) $blur-radius (-$spread-radius);
  }

  &.faded-top.faded-right.faded-bottom:after {
    box-shadow: inset (-$offset/2) 0 $blur-radius $spread-radius;
  }

  &.faded-left.faded-right.faded-bottom:after {
    box-shadow: inset 0 (-$offset/2) $blur-radius $spread-radius;
  }

  &.faded-left.faded-top.faded-bottom:after {
    box-shadow: inset ($offset/2) 0 $blur-radius $spread-radius;
  }

  &.faded-left.faded-top.faded-right:after {
    box-shadow: inset 0 ($offset/2) $blur-radius $spread-radius;
  }

  &.faded-all:after,
  &.faded-left.faded-top.faded-right.faded-bottom:after {
    box-shadow: inset 0 0 $blur-radius $spread-radius;
  }
}




$faded-offset: 40px !default;
$faded-blur-radius: 40px !default;
$faded-spread-radius: 16px !default;

$faded-narrow-offset: 20px !default;
$faded-narrow-blur-radius: 20px !default;
$faded-narrow-spread-radius: 10px !default;

$faded-wide-offset: 80px !default;
$faded-wide-blur-radius: 80px !default;
$faded-wide-spread-radius: 28px !default;

.faded {
  position: relative;
  display: inline-block;
  color: white;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-collapse: separate;
  }

  @include faded($faded-offset, $faded-blur-radius, $faded-spread-radius);

  &.faded-narrow {
    @include faded($faded-narrow-offset, $faded-narrow-blur-radius, $faded-narrow-spread-radius);
  }

  &.faded-wide {
    @include faded($faded-wide-offset, $faded-wide-blur-radius, $faded-wide-spread-radius);
  }
}

.popup-agm {
  background-image: url("../img/popup-2021-1.jpg");

  @media only screen and (max-width: 600px) {
    max-height: none;
    background-image: url("../img/popup-2021-1-mobile.jpg");
    background-position: top left;

    .modal-main .title {
      margin-top: 33vw;
    }
  }
}

// =======================
// Corporate Info Grid
// =============================

.mobile-one-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 600px) {
  .mobile-one-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
  }
}

#accordionMilestone {
  .btn.btn-link {
    width: 100%;
    text-align: left;
  }
  .btn.btn-link:hover,
  .btn.btn-link:focus {
    text-decoration: none;
    border: none;
    outline: none;
  }
  .collapse.in li {
    background-color: transparent !important;
  }
}

#accordionMilestone .fa {
  margin-right: 0.5rem;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
#accordionMilestone button, #accordionMilestone button:hover, #accordionMilestone button:focus{
  text-decoration: none;
}

.container-group-structure {
  @media only screen and (max-width: 767px) {
    padding-right: 1rem;
  }
}
