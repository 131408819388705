// to use these animations,
// wrap the texts in spans
//
// eg: WELCOME TO THIS PLACE
// <span><span>WELCOME TO</span></span>
// <span><span>THIS PLACE</span></span>
//
// first span is the container
// second span is the animated element
//
//place the class on the original element
//
//eg: h1.animated

.pause-anim{
  opacity: 0;

  transition: all 50ms $easeInOutCubic;
 }

.animated{
  // set span elements to inline-block
  span{ display: block; }

  // ======================================================
  // Animation Styles

  // ====================
  // slide up from a mask
  // ========================
  &.slideUpMasked{
    & > span{
      overflow: hidden;

      span{
        opacity: 0;
        animation: {
          name: slideUpMasked;
          timing-function: $easeInOutCubic;
          fill-mode: both;
          duration: 0.6s;
        }
      }
    }
  }
  // ====================
  // fade in from top
  // ========================
  &.fadeInTop{
    span{
      span{
        opacity: 0;
        animation: {
          name: fadeInTop;
          timing-function: $easeInOutCubic;
          fill-mode: both;
          duration: 0.6s;
        }
      }
    }
  }
  // ====================
  // fade in from bottom
  // ========================
  &.fadeInTop{
    span{
      span{
        opacity: 0;
        animation: {
          name: fadeInBottom;
          timing-function: $easeInOutCubic;
          fill-mode: both;
          duration: 0.6s;
        }
      }
    }
  }

  // ======================================================

  //delay each element
  $gap: 1.4;

  $delay: 100ms;
  $delay2: $delay*$gap;
  $delay3: $delay2*$gap;
  $delay4: $delay3*$gap;
  $delay5: $delay4*$gap;
  $delay6: $delay5*$gap;


  &.seq1{
    span:nth-child(2) span{ animation-delay: $delay; }
    span:nth-child(3) span{ animation-delay: $delay*$gap; }
    span:nth-child(4) span{ animation-delay: $delay*$gap; }
  }

  &.seq2{
    span:nth-child(1) span{ animation-delay: $delay2; }
    span:nth-child(2) span{ animation-delay: $delay2*$gap; }
    span:nth-child(3) span{ animation-delay: $delay2*($gap*2); }
    span:nth-child(4) span{ animation-delay: $delay2*($gap*3); }
  }

  &.seq3{
    span:nth-child(1) span{ animation-delay: $delay3; }
    span:nth-child(2) span{ animation-delay: $delay3*$gap; }
    span:nth-child(3) span{ animation-delay: $delay3*($gap*2); }
    span:nth-child(4) span{ animation-delay: $delay3*($gap*3); }
  }
  &.seq4{
    span:nth-child(1) span{ animation-delay: $delay4; }
    span:nth-child(2) span{ animation-delay: $delay4*$gap; }
    span:nth-child(3) span{ animation-delay: $delay4*($gap*2); }
    span:nth-child(4) span{ animation-delay: $delay4*($gap*3); }
  }
  &.seq5{
    span:nth-child(1) span{ animation-delay: $delay5; }
    span:nth-child(2) span{ animation-delay: $delay5*$gap; }
    span:nth-child(3) span{ animation-delay: $delay5*($gap*2); }
    span:nth-child(4) span{ animation-delay: $delay5*($gap*3); }
  }
  &.seq6{
    span:nth-child(1) span{ animation-delay: $delay6; }
    span:nth-child(2) span{ animation-delay: $delay6*$gap; }
    span:nth-child(3) span{ animation-delay: $delay6*($gap*2); }
    span:nth-child(4) span{ animation-delay: $delay6*($gap*3); }
  }
}


@keyframes slideUpMasked{
  0%{ transform: translateY(55%); opacity: 0; }
  100%{ transform: translateY(0); opacity: 1; }
}

@keyframes fadeInTop{
  0%{ transform: translateY(-55%); opacity: 0; }
  100%{ transform: translateY(0); opacity: 1; }
}
@keyframes fadeInBottom{
  0%{ transform: translateY(55%); opacity: 0; }
  100%{ transform: translateY(0); opacity: 1; }
}
