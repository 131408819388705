
/* Imports */

@import 'base/variables';
@import 'base/typography';
@import 'base/utility';

/* UI Element Stylings */
@import 'components/rounded-button';
@import 'components/arrow-button';
@import 'components/scroll-to-top';
@import 'components/submit-slider';

/* Template for Sections */
@import 'templates/header-footer';
@import 'templates/scrolling-list';
@import 'templates/tiles';
@import 'templates/tabs';
@import 'templates/loader';

/* Base Styling */
@import 'base/base';

/* CSS Animations */
@import 'templates/css-animations';
