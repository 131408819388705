$loader-color: rgb(240, 240, 240);
$loader-duration: 1200ms;

//====================
body:not(.version-ie){
  background: $loader-color;
  pointer-events: none;
  header, footer, .main__content{
    opacity: 0 !important;
  }

  &.is-shown{
    background: white;
    pointer-events: initial;
    header, footer, .main__content{
      opacity: 1 !important;
    }
  }

  overflow-y: scroll;
}
//====================

.loader__container{
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  // background: white;

  z-index: 999;
  pointer-events: none;
}

.loader{
  width: 102%;
  height: 100%;

  background: $loader-color;

  transition: all $loader-duration $easeInOutQuint;

  &.exit-loader{
    transform: translateX(-120%) skew(30deg);
  }
}

//===============
// Exiting Animation
//======================
.loader__container.is-entering{
  .loader{
    transform: translateX(-120%) skew(30deg);
  }
}

.loader__container.is-exiting{
  .loader{
    transform: none;
  }
}

//===============
// Body Entering Animation
//======================

@media all and (max-width: 768px){
  .loader{
    &.exit-loader{
      transform: translateX(-155%) skew(30deg);
    }
  }

  .loader__container.is-entering{
    .loader{
      transform: translateX(-155%) skew(30deg);
    }
  }
}
